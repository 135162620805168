<template>
  <b-overlay :show="isLoading">
    <div class="card p-1 rounded-lg">
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 text-hede">
          <i class="far fa-ticket-alt text-primary" /> ซื้อด้วยเพชรและกงล้อ <small />
        </p>

        <i class="far fa-ellipsis-v" />
      </div>

      <div class="my-50">
        <small>ซื้อด้วยเพชร</small>
        <h3 class="mb-25 font-weight-bolder text-primary">
          {{ chip }}
          <span style="font-size: 0.95rem">ใบ</span>
        </h3>
      </div>

      <div class="my-50">
        <small>หมุนกงล้อ</small>
        <h3 class="mb-25 font-weight-bolder text-primary">
          {{ wheel }}
          <span style="font-size: 0.95rem">ใบ</span>
        </h3>
      </div>
    </div>
  </b-overlay>
</template>


<script>
import {
  BSpinner, BRow, BCol, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner, BRow, BCol, BOverlay,
  },
  data() {
    return {
      isLoading: false,
      chip: 0,
      wheel: 0,
    }
  },
  mounted() {
    this.getData('TODAY_LOTTO_ANY');
  },
  methods: {
    async getData(type) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=${type}`);

        this.chip = data.chip;
        this.wheel = data.wheel;
      } catch (error) {
        console.log(error);
      } finally {

        this.isLoading = false;
      }
    }
  }
}
</script>