<template>
  <b-overlay :show="isLoading">
    <div class="card">
      <div class="p-2 h4 font-bolder">
        ยอดเสียทั้งหมดของยูสภายใต้พันธมิตร (THB)
      </div>
      <div class="mt-2 mx-auto">
        <vue-apex-charts type="bar" width="567" height="320" :options="chartOptions" :series="series" />
      </div>
      <!-- {{ data.winLossAll }} -->
      <div class="text-center my-2">
        <p class="mb-0">
          ยอดเสียทั้งหมด
        </p>

        <h2 class="font-weight-bolder">
          {{
    data && data.winLossAll
      ? data.winLossAll.total_winloss.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      : 0
  }}
          ฿
        </h2>
      </div>

      <b-row class="text-center mx-0">
        <b-col class="border-top border-right d-flex align-items-between flex-column py-1">
          <b-card-text class="text-muted mb-0">
            กีฬา
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{
      data && data.winLossAll
        ? data.winLossAll.sport_winloss.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        : 0
    }}
            ฿
          </h3>
        </b-col>

        <b-col class="border-top border-right d-flex align-items-between flex-column py-1">
          <b-card-text class="text-muted mb-0">
            สล็อต
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{
      data && data.winLossAll
        ? data.winLossAll.slot_winloss.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        : 0
    }}
            ฿
          </h3>
        </b-col>

        <b-col class="border-top border-right d-flex align-items-between flex-column py-1">
          <b-card-text class="text-muted mb-0">
            คาสิโน
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{
      data && data.winLossAll
        ? data.winLossAll.casino_winloss.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        : 0
    }}
            ฿
          </h3>
        </b-col>
      </b-row>

      <b-row class="text-center">
        <b-col class="border-top d-flex border-right align-items-between flex-column py-1">
          <b-card-text class="text-muted mb-0">
            โป๊กเกอร์
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{
      data && data.winLossAll
        ? data.winLossAll.poker_winloss.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        : 0
    }}
            ฿
          </h3>
        </b-col>

        <b-col class="border-top d-flex border-right align-items-between flex-column py-1">
          <b-card-text class="text-muted mb-0">
            ล็อตเตอรี่
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{
      data && data.winLossAll
        ? data.winLossAll.lotto_winloss.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            })
            : 0
            }}
            ฿
          </h3>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BTable, BCardText, BOverlay
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    VueApexCharts,
    BCardText, BOverlay
  },
  props: ['data', 'winLossAllSeries'],
  data() {
    return {
      chartOptions: {
        chart: {
          width: '100%',
          height: 500,
          type: 'bar',
        },
        series: [],
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ฿`
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#7367f0'],
          },
        },
        xaxis: {
          categories: ['กีฬา', 'สล็อต', 'คาสิโน', 'โป๊กเกอร์', 'ล็อตเตอรี่'],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#7367f0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter(val) {
              return `${val.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ฿`
            },
          },
        },
      },
      series: [],
      isLoading: false
    }
  },
  mounted() {
    this.getData('ZEAN');
  },
  methods: {
    async getData(type) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=${type}`);

        this.series = [
          {
            name: 'ยอดเสีย',
            data: [
              data.sport_winloss,
              data.slot_winloss,
              data.casino_winloss,
              data.poker_winloss,
              data.lotto_winloss,
            ],
          },
        ];
      } catch (error) {
        console.log(error);
      } finally {

        this.isLoading = false;
      }
    }
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
</style>
