<template>
  <div class="card">
    <div class="h4 font-bolder text-danger asdasd p-2">
      สรุปรายเดือน (KRW)
    </div>

    <div class="d-block d-md-none px-1 py-25">
      <div
        v-for="(monthData, year) in reportYear"
        :key="year"
        class="card-mobile"
      >
        <div class="d-flex align-items-center justify-content-between">
          <!-- <span class="card__actv">
                  ผู้เล่น {{ item.active }}
                </span> -->

          <small class="text-black">
            {{ year }}
          </small>
        </div>

        <div
          class="d-flex pt-50 pb-25"
          style="gap: 10px"
        >
          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              ยอดฝาก
            </div>

            <div class="text-premier font-weight-bolder font-medium-2">
              {{
                getTotalSum(monthData, "deposit_kr").toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )
              }}
            </div>
          </div>

          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              ยอดถอน
            </div>

            <div class="text-danger font-weight-bolder font-medium-2">
              {{
                getTotalSum(monthData, "withdraw_kr").toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )
              }}
            </div>
          </div>

          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              กำไรสุทธิ
            </div>
            <div
              :class="getNumberClass(getTotalSum(monthData, 'total'))"
              class="text-premier font-weight-bolder font-medium-2"
            >
              {{
                getTotalSum(monthData, "total").toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )
              }} xxx
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive d-none d-md-block">
      <table class="table b-table w-full table-striped table-hover">
        <thead>
          <tr>
            <th>วันที่</th>
            <th>ยอดฝาก</th>
            <th>ยอดถอน</th>
            <th>กำไรสุทธิ</th>
            <th>Report</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(monthData, year) in reportYear"
            :key="year"
          >
            <td>{{ year }}</td>
            <td>
              {{
                getTotalSum(monthData, "deposit_kr").toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )
              }}
            </td>
            <td>
              {{
                getTotalSum(monthData, "withdraw_kr").toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )
              }}
            </td>
            <td
              :class="getNumberClass(getNetTotal(monthData))"
              style="font-weight: 700"
            >
              {{
                getNetTotal(monthData).toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )
              }} ({{ ((getNetTotal(monthData) * 100) / getTotalSum(monthData, "deposit_kr")).toFixed(2) }} %)
            </td>
            <td>
              <b-button v-b-modal.dailyReportModalKr size="sm" variant="primary"
                @click="getReportDayFilter(year.split('-')[1], year.split('-')[0])">รายงาน</b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal size="xl" id="dailyReportModalKr" title="รายงาน">
      <div class="table-responsive d-none d-md-block">
        <table class="table b-table w-full table-striped table-hover table-fixed" v-if="reportFilter.length">
          <thead>
          <tr>
            <th>วันที่</th>
            <th>ยอดสมัคร</th>
            <th>สมัครและฝาก</th>
            <th>active</th>
            <th>ยอดฝาก</th>
            <th>ยอดฝากแรก</th>
            <th>ค่าเฉลี่ยฝากแรก(วอน)</th>
            <th>ค่าเฉลี่ยฝากแรก(%)</th>
            <th>ยอดถอน</th>
            <th>กำไรสุทธิ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in reportFilter"
            :key="index"
          >
            <td>{{ item.created_at | date }}</td>
            <td>{{ item.regis_kr ? item.regis_kr: 0 }}</td>
            <td>
              {{ item.regisdep_kr ? item.regisdep_kr : 0 }}
            </td>
            <td>{{ item.active }}</td>
            <td>
              {{ item.deposit_kr ? item.deposit_kr.toLocaleString() : 0 }}
            </td>
            <td>
              {{ item.depfirst_sum_kr ? item.depfirst_sum_kr.toLocaleString() : 0 }}
            </td>
            <td>
              {{
                item.depfirst_sum_kr && item.depfirst_count_kr
                  ? (item.depfirst_sum_kr / item.depfirst_count_kr).toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )
                  : 0
              }}
            </td>
            <td>
              {{
                item.depfirst_count_kr && item.regis_kr
                  ? ((item.depfirst_count_kr * 100) / item.regis_kr).toLocaleString(
                    undefined,
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )
                  : 0
              }} %
            </td>
            <td>
              {{ item.withdraw_kr }}
            </td>
            <td
              :class="{
                'text-success': item.total_kr >= 0,
                'text-danger': item.total_kr < 0,
              }"
            >
              {{ item.total_kr }}
            </td>
          </tr>
        </tbody>
          <tfoot style="background-color: #f3f2f7">
            <tr>
              <td colspan="8">
                กำไรสุทธิ
              </td>
              <td class="font-weight-bold" :class="{
        'text-success': profitDays >= 0,
        'text-danger': profitDays < 0,
      }">
                {{
                profitDays ? profitDays.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                }) : 0
                }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BRow, BCol } from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BModal, BButton, BRow,
    BCol,
  },
  filters: {
    date(val) {
      return moment(val).utc().subtract(-1).format('DD-MM-YYYY')
    },
  },
  props: ['reportYear', 'isLoading'],
  data() {
    return {
      // reportDay: [],
      // reportYear: [],
      // profitDays: '',
      fields: [
        {
          key: 'created_at',
          label: 'วันที่',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'active', label: 'ยอดผู้เล่น' },
        { key: 'deposit', label: 'ยอดฝาก' },
        { key: 'withdraw', label: 'ยอดถอน' },
        { key: 'total', label: 'กำไรสุทธิ' },
        { key: 'profitDay', label: 'กำไรสุทธิ' },
      ],
      reportFilter: []
    }
  },
  mounted() {
    // this.getDay()
  },
  methods: {
    getReportDayFilter(month, year) {
      this.$http
        .get(`dashboard/ReportDaysAndYearsFilter?month=${month}&year=${year}`)
        .then(response => {
          this.reportFilter = response.data.days
        })
        .catch(error => console.log(error))
    },
    getDay() {
      this.$http
        .get('dashboard/ReportDaysAndYears')
        .then(response => {
          this.reportDay = response.data.days
          this.profitDays = response.data.profitDay
          // console.log(response.data.days)
          this.reportYear = response.data.years
          // console.log(this.reportYear)
        })
        .catch(error => console.log(error))
    },
    getNetTotal(monthData) {
      return monthData.reduce((total, item) => {
        const deposit = item.deposit_kr || 0
        const withdraw = item.withdraw_kr || 0
        return total + (deposit - withdraw)
      }, 0)
    },
    getTotalSum(monthData, field) {
      return monthData.reduce((total, item) => total + (item[field] ? item[field] : 0), 0)
    },
    getTotalSumForAllMonths(field) {
      let totalSum = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.reportYear) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.reportYear.hasOwnProperty(key)) {
          totalSum += this.getTotalSum(this.reportYear[key], field)
        }
      }
      return totalSum
    },
    fmDate(value) {
      return moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    getNumberClass(number) {
      return {
        'text-success': number > 0,
        'text-danger': number < 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
